@media only screen and (min-width: 768px) {
    .logo-img {
      width: 100px;
      margin: 1rem 2rem 0 2rem;
    }
      .navbar {
        overflow: hidden;
        position: fixed;
        color: rgba(56, 102, 65);
        left: 0;
        top: 0;
        padding: 0;
        margin: 0;
        width: 19.7%;
        height: 100%;
        background-color: white;
      }
      
      .nav-content {
        margin: 0;
      }
  
      .nav-items {
        margin: 0px;
        padding: 0px;
      }
      
      .nav-item {
        font-size: 22px;
        list-style-type: none;
      }
      
      .clickyButton {
        margin: 20px auto;
        padding: 10px 0px;
      }
      
      .resumeButton {
        list-style-type: none;
        background-color: rgba(56, 102, 65);
        color: white;
        width: 100%;
        padding: 5px 5px;
        font-size: 20px;
        position: absolute;
        bottom: 5px;
      }
      
      .resumeButton a{
        color: white;
        text-decoration: none;
      }
      
      .clickyButton:hover {
        color: #999;
        transition: color 0.5s;
      }
      
      .nav-item a:hover {
        text-decoration: none;
        color: black;
      }
      
      .resumeButton:hover {
        color: black;
        transition: color 0.5s;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,1);
        transition: box-shadow 0.2s;
        font-size: 21px;
        transition: font-size 0.2s;
      }
      
      .resumeButton a:hover {
        text-decoration: none;
      }
      }
      @media only screen and (max-width: 768px) {
        .logo-img {
          width: 100px;
          margin: 1rem 2rem 0 2rem;
        }
          .navbar {
            position: fixed;
            color: rgba(56, 102, 65);
            background-color: white;
            left: 0;
            top: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
          
          .nav-content {
            margin: 0;
          }
      
          .nav-items {
            margin: 0 0 0 0;
          }
          
          .nav-item {
            font-size: 22px;
            list-style-type: none;
          }
          
          .clickyButton {
            margin: 10px auto;
            padding: 10px 15px;
          }
          
          .resumeButton {
            bottom: 1rem;
            background-color:  rgb(24, 83, 35) ;
            border-radius: 5px;
            color: white;
            list-style-type: none;
            padding: 5px 30px;
            font-size: 20px;
          }
          
          .resumeButton a{
            font-size: 15px;
            color: white;
            text-decoration: none;
          }
          
          .clickyButton:hover {
            color: #999;
            text-decoration: underline;
            transition: color 0.5s;
          }
          
          .nav-item a:hover {
            text-decoration: none;
            color: black;
          }
          
          .resumeButton:hover {
            color: white;
            transition: color 0.5s;
          }
          
          .resumeButton a:hover {
            text-decoration: none;
            color: black;
          }
      }



      