.boss-cropper {
    width: 300px;
}

.pavit-cropper {
    width: 300px;
    height: 500px;
}
.trimmed-boss-row-card {
    display: flex;
}

.row-card-whoweare {
    padding: 20px;
    margin: 0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
}

.row-card-whoweare2 {
    margin-top: 50px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
}

.row-card-whoweare3 {
    margin-top: 50px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
}