.footer {
    display: flex;
    flex-flow: row wrap;
    flex-direction: right;
    padding: 30px 30px 20px 30px;
    color: #2f2f2f;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    width: 80%;
    margin-left: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .footer > * {
    flex:  1 100%;
  }
  
  .footer__addr {
    margin-right: 1.25em;
    margin-bottom: 2em;
  }

  .footer-column1 {
    width: 50%;
    float: left;
  }

  .footer-column2 {
    width: 50%;
    float: right;
  }
  
  .footer__logo {
    height: 100px;
    width: 400px;
    margin: 0 auto;
  }

  .footer__logo_cityview {
    height: 150px;
    width: 400px;
    margin: 0 auto;
  }
  
  .footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
    font-weight: 400;
  }
  
  .nav__title {
    font-weight: 400;
    font-size: 15px;
  }
  
  .footer address {
    font-style: normal;
    color: #999;
  }
  
  .footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  .footer li {
    line-height: 2em;
  }
  
  .footer a {
    text-decoration: none;
  }
  
  .footer__nav {
    display: flex;
    flex-flow: row wrap;
  }

  .footer_link {
    display: inline-block;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,0.2);
    margin-top: 20px;
  }
  
  .footer__nav > * {
    flex: 1 50%;
    margin-right: 1.25em;
  }
  
  .nav__ul a {
    color: #999;
  }

  .nav__ul p {
    color: #999;
    margin: 0;
    padding: 0;
  }
  
  .nav__ul--extra {
    column-count: 2;
    column-gap: normal;
  }
  
  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
  }
    
  .legal__links {
    display: flex;
    align-items: center;
  }
  
  .heart {
    color: #2f2f2f;
  }
  
  @media screen and (min-width: 24.375em) {
    .legal .legal__links {
      margin-left: auto;
    }
  }
  
  @media screen and (min-width: 40.375em) {
    .footer__nav > * {
      flex: 1;
    }
    
    .nav__item--extra {
      flex-grow: 2;
    }
    
    .footer__addr {
      flex: 1 0px;
    }
    
    .footer__nav {
      flex: 2 0px;
    }
  }

  @media screen and (max-width: 768px) {
    
    .footer-column1 {
      width: 100%;
    }  
    .footer-column2 {
      width: 100%;
    }    
  }