body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  text-align: center;
  color: black;
  width: 80%;
}

.row-card {
height: 100%;
text-align: center;
margin: 0 auto;
}

.title-row-card {
font-size: 20px;
}

.backdrop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1;
}
.modal {
  background-color: rgba(0,0,0,0.5);
  width: 500px;
  margin: 0 auto;
  padding: 40px 20px;
  background: white;
  border-radius: 10px;
  text-align: center;
}

.modal p {
  color: #444;
  font-weight: bold;
}

.modal button {
  color: #444;
  border-color: #444;
  font-weight: bold;
  margin-top: 20px;
}

.quote-form {
  display: grid;
}